import { extraReducersBuilder } from './queueExtraReducers';
import { queueReducers } from './queueReducers';
import { thunkActions } from './queueThunks';
import { QueueCustomerModel, ScheduledBreakModel } from '@models';
import { createSlice } from '@reduxjs/toolkit';

export interface SalonWaitingTime {
  waitTime: string;
  state: 'Valid' | 'Unavailable' | 'Freeze' | string;
}

export enum QueueItemType {
  Undefined = 0,
  Customer = 1,
  Break = 2,
}

export interface QueueItem {
  type: QueueItemType;
  data: QueueCustomerModel;
}

export interface QueueStateModel {
  salonId: string | null;
  simulationTime: string;
  salonWaitingTime: SalonWaitingTime;
  customers: QueueCustomerModel[];
  employeeCustomers: QueueItem[];
  pendingCustomers: QueueCustomerModel[];
  selectedCustomer: QueueCustomerModel | null;
  customerInProgress: QueueCustomerModel | null;
  scheduledBreaks: ScheduledBreakModel[];
  error: string | null;
};

const initialState: QueueStateModel = {
  simulationTime: '',
  salonId: null,
  customers: [],
  employeeCustomers: [],
  salonWaitingTime: {
    waitTime: '',
    state: '',
  },
  pendingCustomers: [],
  selectedCustomer: null,
  customerInProgress: null,
  scheduledBreaks: [],
  error: null,
};

export const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: queueReducers,
  extraReducers: extraReducersBuilder(initialState),
});

export const {
  fetchQueue,
  addCustomerToQueue,
  removeCustomerFromQueue,
  selectCustomer,
  deselectCustomer,
  setCustomerInProgress,
  updateQueue,
  cleanUpQueueAfterHaircut,
  setScheduledBreaks,
} = {
  ...queueSlice.actions,
  ...thunkActions,
};

export default queueSlice.reducer;
