import { ErrorMessage } from './ErrorMessage';
import { useAPIError } from 'hooks';
import * as React from 'react';

export const APIErrorNotification: React.FC = () => {
  const { error } = useAPIError();

  if (error) {
    return <ErrorMessage message={error?.message} />;
  }

  return null;
};
