import { CutNote } from '..';
import { useTranslation } from 'next-i18next';

export const useAllCutNotes = (): CutNote[] => {
  const { t } = useTranslation('cutNote');

  return [
    {
      name: t('common.topSidesBack.label'),
      items: [
        {
          label: t('male.topSidesBack.items.machineLength.label'),
          name: 'machineCuttingLength',
          limit: 3,
          options: [
            {
              label: '0',
              value: '0',
            },
            {
              label: '1',
              value: '1',
            },
            {
              label: '1.5',
              value: '1.5',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
            {
              label: '4.5',
              value: '4.5',
            },
            {
              label: '6',
              value: '6',
            },
            {
              label: '8',
              value: '8',
            },
            {
              label: '9',
              value: '9',
            },
            {
              label: '10',
              value: '10',
            },
            {
              label: '12',
              value: '12',
            },
            {
              label: '13',
              value: '13',
            },
            {
              label: '16',
              value: '16',
            },
            {
              label: '19',
              value: '19',
            },
            {
              label: '22',
              value: '22',
            },
            {
              label: '25',
              value: '25',
            },
          ],
        },
        {
          label: t('male.topSidesBack.items.scissor.label'),
          name: 'scissor',
          options: [
            {
              label: t('common.yes'),
              value: true,
            },
            {
              label: t('common.no'),
              value: false,
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.cutLine.label'),
          name: 'cutLine',
          options: [
            {
              label: t('female.topSidesBack.items.cutLine.options.vShape'),
              value: 'vShape',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.round'),
              value: 'round',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.straight'),
              value: 'straight',
            },
            {
              label: t('female.topSidesBack.items.cutLine.options.bob'),
              value: 'bob',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.shapeStyle.label'),
          name: 'shapeStyle',
          options: [
            {
              label: t('female.topSidesBack.items.shapeStyle.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.shapeStyle.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.shapeStyle.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.front.label'),
          name: 'front',
          options: [
            {
              label: t('female.topSidesBack.items.front.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.front.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.front.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.back.label'),
          name: 'back',
          options: [
            {
              label: t('female.topSidesBack.items.back.options.layered'),
              value: 'layered',
            },
            {
              label: t('female.topSidesBack.items.back.options.graduated'),
              value: 'graduated',
            },
            {
              label: t('female.topSidesBack.items.back.options.oneLength'),
              value: 'oneLength',
            },
          ],
        },
        {
          label: t('female.topSidesBack.items.bangs.label'),
          name: 'bangs',
          options: [
            {
              label: t('female.topSidesBack.items.bangs.options.curtain'),
              value: 'curtain',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.straight'),
              value: 'straight',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.side'),
              value: 'side',
            },
            {
              label: t('female.topSidesBack.items.bangs.options.round'),
              value: 'round',
            },
          ],
        },
      ],
    },
    {
      name: t('common.connection.label'),
      items: [
        {
          label: t('common.connection.label'),
          name: 'connection',
          limit: 2,
          options: [
            {
              label: t('common.connection.options.blended'),
              value: 'blended',
            },
            {
              label: t('common.connection.options.disconnected'),
              value: 'disconnected',
            },
          ],
        },
      ],
    },
    {
      name: t('common.details.label'),
      items: [
        {
          label: t('common.details.items.thinnedOut.label'),
          name: 'thinnedOut',
          limit: 3,
          options: [
            {
              label: t('common.details.items.thinnedOut.options.bangs'),
              value: 'bangs',
            },
            {
              label: t('common.details.items.thinnedOut.options.connection'),
              value: 'connection',
            },
            {
              label: t('common.details.items.thinnedOut.options.top'),
              value: 'top',
            },
          ],
        },
        {
          label: t('common.details.items.hairAroundEars.label'),
          name: 'hairAroundEars',
          options: [
            {
              label: t('common.details.items.hairAroundEars.options.freeEars'),
              value: 'freeEars',
            },
            {
              label: t('common.details.items.hairAroundEars.options.overEars'),
              value: 'overEars',
            },
          ],
        },
        {
          label: t('male.details.items.eyebrows.label'),
          name: 'eyebrows',
          options: [
            {
              label: t('common.yes'),
              value: true,
            },
            {
              label: t('common.no'),
              value: false,
            },
          ],
        },
        {
          label: t('common.details.items.neckLine.label'),
          name: 'neckLine',
          limit: 1,
          options: [
            {
              label: t('common.details.items.neckLine.options.line'),
              value: 'line',
            },
            {
              label: t('common.details.items.neckLine.options.naturalBlend'),
              value: 'naturalBlend',
            },
          ],
        },
        {
          label: t('common.details.items.sideBurns.label'),
          name: 'sideBurns',
          limit: 1,
          options: [
            {
              label: t('common.details.items.sideBurns.options.beginning'),
              value: 'beginning',
            },
            {
              label: t('common.details.items.sideBurns.options.mid'),
              value: 'mid',
            },
            {
              label: t('common.details.items.sideBurns.options.blended'),
              value: 'blended',
            },
            {
              label: t('common.details.items.sideBurns.options.long'),
              value: 'long',
            },
          ],
        },
        {
          label: t('male.details.items.earHair.label'),
          name: 'earHair',
          options: [
            {
              label: t('common.yes'),
              value: true,
            },
            {
              label: t('common.no'),
              value: false,
            },
          ],
        },
      ],
    },
  ];
};
