import { RootState } from '../../store';
import { useSelector } from 'react-redux';

export const useScheduledBreaks = (transactionId: string | undefined) => {
  const scheduledBreaks = useSelector((state: RootState) => {
    return state.queue.scheduledBreaks.filter(scheduledBreak => scheduledBreak.transactionId === transactionId);
  });

  return { scheduledBreaks };
};
