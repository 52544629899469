import { useState, useEffect, useRef } from 'react';

export const useTimer = (startTime: Date) => {
  const [millisecondsSince, setMillisecondsSince] = useState(0);
  const animationFrameRef = useRef(0);

  const incrementTime = () => {
    setMillisecondsSince(Date.now() - startTime.getTime());
    animationFrameRef.current = requestAnimationFrame(incrementTime)
  }

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(incrementTime);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, []);

  return formatTime(millisecondsSince);
};

const formatTime = (timeInMs: number) => {
  const seconds = timeInMs / 1000;

  const secondsPassed = Math.floor(seconds % 60);
  const minutesPassed = Math.floor(seconds / 60) % 60;
  const hoursPassed = Math.floor(seconds / 3600);

  return {
    seconds: secondsPassed < 10 ? `0${secondsPassed}` : secondsPassed.toString(),
    minutes: minutesPassed < 10 ? `0${minutesPassed}` : minutesPassed.toString(),
    hours: hoursPassed < 10 ? `0${hoursPassed}` : hoursPassed.toString(),
  };
};
