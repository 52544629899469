import { QueueStateModel } from '../queueSlice';
import { ScheduledBreakModel } from '@models';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetScheduledBreaksActionPayload {
  scheduledBreaks: ScheduledBreakModel[];
}

export const setScheduledBreaks = (state: QueueStateModel, action: PayloadAction<SetScheduledBreaksActionPayload>) => {
  state.scheduledBreaks = action.payload.scheduledBreaks;
};
