import { BookingCustomerModel } from '@models';
import { BookingsStateModel } from './bookingsSlice';
import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { isToday } from 'date-fns';

interface AddUpdateBookingPayload {
  booking: BookingCustomerModel;
}

const filterAndSortBookingCustomers = (customers: BookingCustomerModel[]) => customers
  .filter((booking) => isToday(new Date(booking.appointment.bookingTime)))
  .sort((a: BookingCustomerModel, b: BookingCustomerModel) => 
    a.appointment.bookingTime > b.appointment.bookingTime ? 1 : -1
  );

export const setBookings = (state: Draft<BookingsStateModel>, newState: BookingsStateModel) => {
  state.customers = filterAndSortBookingCustomers(newState.customers);
  state.selectedBookingCustomer = newState.selectedBookingCustomer;
  state.salonId = newState.salonId;
};

export const addBooking = (state: BookingsStateModel, action: PayloadAction<AddUpdateBookingPayload>) => {
  const serverBooking = <BookingCustomerModel> action.payload.booking;

  state.customers = filterAndSortBookingCustomers([...state.customers, serverBooking]);
};

export const updateBooking = (state: BookingsStateModel, action: PayloadAction<AddUpdateBookingPayload>) => {
  const serverBooking = <BookingCustomerModel> action.payload.booking;

  const bookingIndex = state.customers.findIndex((booking) => booking.id === serverBooking.id);

  if (bookingIndex !== -1) {
    state.customers[bookingIndex] = serverBooking;
  }
}

export const removeBooking = (state: Draft<BookingsStateModel>, action: PayloadAction<{ id: string }>) => {
  state.customers = state.customers.filter((c: BookingCustomerModel) => c.id !== action.payload.id);
  if (state.selectedBookingCustomer?.id === action.payload.id) {
    state.selectedBookingCustomer = null;
  }
}