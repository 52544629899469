import { HairdressersStateModel } from '../hairdressersSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { Hairdresser } from '../../../models';

interface SetCurrentHairdresserActionPayload {
  hairdresser: Hairdresser;
}

export const setCurrentHairdresser = (
  state: HairdressersStateModel, action: PayloadAction<SetCurrentHairdresserActionPayload>): void => {
  state.currentHairdresser = action.payload.hairdresser;
};
