import { StateReducers } from '../types/storeTypes';
import {
  deselectNoshowCustomer,
  selectNoshowCustomer,
} from './actions';
import { NoshowQueueStateModel } from './noshowQueueSlice';
import { addNoshowCustomerState, removeNoshowCustomerState } from './noshowQueueStateFunctions';

export const noshowQueueReducers: StateReducers<NoshowQueueStateModel> = {
  selectNoshowCustomer,
  deselectNoshowCustomer,
  addNoshowCustomerState,
  removeNoshowCustomerState
};
