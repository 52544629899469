import { Shift } from 'models';
import { useGet, useCurrentSalon, useErrorHandler } from 'hooks';

export const useCurrentSalonAllShifts = () => {
  const { salon } = useCurrentSalon();
  const salonId = salon?.id ?? null;
  const { data, error } = useGet<Shift>(salonId ? `/shifts/salon/${salonId}` : null);

  useErrorHandler(data, error, 'failedToFetchShifts');

  return {
    salonShift: data,
    isLoading: !error && !data,
    error: error,
  };
};
