import { fetchNoshowQueue, NoshowQueueStateModel, addNoshowCustomer, deleteNoshowCustomer, requeueNoshowCustomer } from './noshowQueueSlice';
import { setNoshowQueue, addNoshowCustomerState, removeNoshowCustomerState } from './noshowQueueStateFunctions';
import { ReducerBuilder } from 'store/types/storeTypes';
import { FetchNoshowQueueResponse } from './noshowQueueThunks';
import { PayloadAction } from '@reduxjs/toolkit';
import { NoshowCustomerModel } from '@models';

export const extraReducersBuilder =
  (initialState: NoshowQueueStateModel) => (builder: ReducerBuilder<NoshowQueueStateModel>) => {
    builder
      .addCase(
        fetchNoshowQueue.fulfilled,
        (state, action: PayloadAction<FetchNoshowQueueResponse>) => setNoshowQueue(state, {...state, ...action.payload })
      )
      .addCase(fetchNoshowQueue.rejected, (state, action) => {
        state.error = action.error.message;
        setNoshowQueue(state, initialState);
      })
      .addCase(
        addNoshowCustomer.fulfilled,
        (state, action: PayloadAction<NoshowCustomerModel>) => {
          addNoshowCustomerState(state, action);
        }
      )
      .addCase(
        deleteNoshowCustomer.fulfilled,
        (state, action: PayloadAction<{ transactionId: string }>) => {
          removeNoshowCustomerState(state, action);
        }
      )
      .addCase(
        requeueNoshowCustomer.fulfilled,
        (state, action: PayloadAction<{ transactionId: string }>) => {
          removeNoshowCustomerState(state, action);
        }
      )
  };
