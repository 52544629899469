import { API } from '@hooks';
import { BookingCustomerModel } from '@models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { urlWithParams } from '@utils/helpers';
import { API_BASE_URL } from 'utils/global-constants';

export interface FetchBookingsResponse {
  bookings: BookingCustomerModel[];
}

export interface FetchBookingsPayload {
  salonId: string | undefined;
}

export const thunkActions = {
  fetchBookings: createAsyncThunk(
    'bookings/fetchBookings',
    async (data: { token: string; payload: FetchBookingsPayload }): Promise<FetchBookingsResponse | undefined> => {
      if (!data?.payload.salonId) {
        return;
      }
      const response = await fetch(urlWithParams(API_BASE_URL + API.GET_PENDING_BOOKINGS, { salonId: data.payload.salonId}), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${data.token}`,
        },
      });

      return Promise.resolve({
        bookings: await response.json(),
      });
    }),
    setCustomerBookingDue: createAsyncThunk(
    'bookings/setCustomerBookingDue',
    async (data: { token: string; id: string }) => {
      return apiStartBooking(data.token, data.id);
    },
  ),
};

const apiStartBooking = async (token: string, id: string) => {
  const response = await fetch(urlWithParams(API_BASE_URL + API.START_BOOKING, { bookingId: id }), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,

    },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(response.statusText);
};
