import { thunkActions } from './priceruleThunks';
import { PriceRule } from '@models';
import { createSlice } from '@reduxjs/toolkit';
import { priceruleReducers } from './priceruleReducers';
import { extraReducersBuilder } from './priceruleExtraReducers';

export type PricerulesStateModel = {
  couponCode: string;
  priceRules: PriceRule[];
  error: string | null;
};

const initialState: PricerulesStateModel = {
  couponCode: '',
  priceRules: [],
  error: null,
};

export const priceruleSlice = createSlice({
  name: 'pricerules',
  initialState,
  reducers: priceruleReducers,
  extraReducers: extraReducersBuilder(initialState),
});

export const {
  fetchPricerules,
} = {
  ...priceruleSlice.actions,
  ...thunkActions,
};

export default priceruleSlice.reducer;
