import { CutNoteStateModel } from '../cutNoteSlice';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetCutNoteNameActionPayload {
  name: string;
}

export const setCutNoteName = (state: CutNoteStateModel, action: PayloadAction<SetCutNoteNameActionPayload>): void => {
  state.name = action.payload.name;
};
