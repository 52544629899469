import { CutNoteStateModel, DetailValueType } from '../cutNoteSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { CutNote } from '@models';

interface SetCutAllNotesActionPayload {
  allCutNotes: CutNote[];
}

export const setAllCutNotes = (state: CutNoteStateModel, action: PayloadAction<SetCutAllNotesActionPayload>) => {
  state.allCutNotes = action.payload.allCutNotes;
};
