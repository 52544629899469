import { thunkActions } from './bookingsThunks';
import { BookingCustomerModel } from '@models';
import { createSlice } from '@reduxjs/toolkit';
import { bookingReducers } from './bookingReducers';
import { extraReducersBuilder } from './bookingsExtraReducers';

export type BookingsStateModel = {
  salonId: string | null;
  customers: BookingCustomerModel[];
  selectedBookingCustomer: BookingCustomerModel | null;
  error: string | null;
};

const initialState: BookingsStateModel = {
  salonId: null,
  customers: [],
  selectedBookingCustomer: null,
  error: null,
};

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: bookingReducers,
  extraReducers: extraReducersBuilder(initialState),
});

export const {
  fetchBookings,
  selectCustomer,
  deselectCustomer,
  setCustomerBookingDue,
  addBooking,
  updateBooking,
  removeBooking,
} = {
  ...bookingsSlice.actions,
  ...thunkActions,
};

export default bookingsSlice.reducer;
