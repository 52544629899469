import { StateReducers } from '../types/storeTypes';
import {
  allowCutNote,
  calculateFormState,
  rejectCutNote,
  resetCutNoteDetails,
  setCutNote,
  setCutNoteDetails,
  setResetCount,
  setCutNoteGender,
  setCutNoteName,
  setAllCutNotes,
  setSelectedCutNoteId,
} from './actions';
import { CutNoteStateModel } from './cutNoteSlice';

export const cutNoteReducers: StateReducers<CutNoteStateModel> = {
  allowCutNote,
  calculateFormState,
  rejectCutNote,
  resetCutNoteDetails,
  setCutNote,
  setCutNoteDetails,
  setCutNoteGender,
  setResetCount,
  setCutNoteName,
  setAllCutNotes,
  setSelectedCutNoteId,
};
