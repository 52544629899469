import hairdressersReducer, { HairdressersStateModel } from './store/hairdressers/hairdressersSlice';
import cutNoteReducer, { CutNoteStateModel } from './store/cutNote/cutNoteSlice';
import queueReducer, { QueueStateModel } from './store/queue/queueSlice';
import bookingsReducer, { BookingsStateModel } from 'store/bookings/bookingsSlice';
import pricerulesReducer, { PricerulesStateModel } from 'store/pricerules/priceruleSlice';
import noshowQueueReducer, { NoshowQueueStateModel } from 'store/noshowQueue/noshowQueueSlice';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

export interface RootState {
  queue: QueueStateModel;
  noshowQueue: NoshowQueueStateModel;
  hairdressers: HairdressersStateModel;
  cutNote: CutNoteStateModel;
  bookings: BookingsStateModel;
  priceRules: PricerulesStateModel;
}

const store = configureStore<RootState>({
  reducer: {
    queue: queueReducer,
    noshowQueue: noshowQueueReducer,
    cutNote: cutNoteReducer,
    hairdressers: hairdressersReducer,
    bookings: bookingsReducer,
    pricerules: pricerulesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
