import { RootState } from '../../store';
import { useSelector } from 'react-redux';

export const useEmployeeScheduledBreaks = (employeeId: number | undefined) => {
  const employeeScheduledBreaks = useSelector((state: RootState) => {
    if(!employeeId) {
      return undefined;
    }

    return state.queue.scheduledBreaks.filter(scheduledBreak => scheduledBreak.employeeId === `${employeeId}`);
  });

  return { employeeScheduledBreaks };
};
