import { HairdressersStateModel } from '../hairdressersSlice';
import { HairdresserServerModel } from '../../../models';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetHairdressersActionPayload {
  salonId: number;
  hairdressers: HairdresserServerModel[];
}

export const setHairdressers = (
  state: HairdressersStateModel,
  action: PayloadAction<SetHairdressersActionPayload>
): void => {
  state.hairdressers = action.payload.hairdressers.map((hairdresser) => {
    return {
      id: hairdresser.id,
      displayName: hairdresser.displayName,
      initials: hairdresser.initials,
      consent: hairdresser.consent,
      workStatus: {
        employeeId: hairdresser.id,
        salonId: action.payload.salonId,
        state: hairdresser.state,
        since: hairdresser.since,
        until: hairdresser.until,
      },
    };
  });
};
