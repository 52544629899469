import { OpeningHours } from '../../models/openingHours.model';
import { useGet, useCurrentEmployeeWorkStatus, useErrorHandler } from 'hooks';

export const useCurrentSalonOpeningHours = () => {
  const { workStatus } = useCurrentEmployeeWorkStatus();
  const salonId = workStatus?.salonId ?? null;
  const { data, error } = useGet<{ openinghours: OpeningHours }>(salonId ? `/openinghours/salons/${salonId}` : null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useErrorHandler(data, error, 'failedToFetchOpeningHours');

  return {
    openingHours: data?.openinghours,
    isLoading: !error && !data,
    error: error,
  };
};
