import { QueueStateModel } from '../queueSlice';
import { forceCustomerStateUpdate } from '../queueStateFunctions';
import { PayloadAction } from '@reduxjs/toolkit';

export const cleanUpQueueAfterHaircut = (state: QueueStateModel, action: PayloadAction<string>) => {
  const transactionId = action.payload;
  state.customerInProgress = null;
  state.selectedCustomer = null;

  forceCustomerStateUpdate(state, transactionId);
};
