import { useGet, useErrorHandler, useHairdresser } from 'hooks';
import { WorkStatus } from 'models';

export const useCurrentEmployeeWorkStatus = () => {
  const { employee } = useHairdresser();
  const { data, error } = useGet<WorkStatus>(employee ? `/workstatuses/employees/${employee.id}` : null);

  useErrorHandler(data, error, 'failedToFetchWorkStatus');

  return {
    workStatus: data,
    isLoading: !error && !data,
    error: error,
  };
};
