import { API } from './constants';
import {
  AddEmployeeFeedbackPayload,
  AddShiftPeriodPayload,
  AddTransactionPayload,
  CancelScheduledBreakPayload,
  CutNotePayload,
  RemoveShiftPeriodPayload,
  ScheduleBreakPayload,
  SendNotificationPayload,
  SendVoucherPayload,
  UpdateEmployeeWorkStatusPayload,
  UpdateSalonInventoryPayload,
  UpdateSingleSalonInventoryProductPayload,
  UpdateTransactionPayload,
} from './payload';
import { useAPIError } from './useAPIError';
import { useAuth0 } from '@auth0/auth0-react';
import { Employee, WorkStatus, Transaction, CutNote, Shift } from '@models';
import { API_BASE_URL, BASE_APP_URL } from '@utils/global-constants';
import { urlWithParams } from '@utils/helpers';
import { Operation } from 'fast-json-patch/commonjs/core';
import _ from 'lodash';
import { useTranslation } from 'next-i18next';

interface CuttersFetchAdapter extends RequestInit {
  params?: any;
  baseUlr?: string;
}

const useCuttersFetchAdapter = () => {
  const { getAccessTokenSilently } = useAuth0();

  return async (path: string, options: CuttersFetchAdapter = {}) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    const { params = null, baseUlr = API_BASE_URL, ...rest } = options;
    const defaultOptions: CuttersFetchAdapter = {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    let url = '';

    if (params === null) {
      url += baseUlr + path;
    } else {
      url += urlWithParams(baseUlr + path, params);
    }

    return await fetch(url, _.merge(defaultOptions, rest));
  };
};

export const useCuttersApi = () => {
  const cuttersFetch = useCuttersFetchAdapter();
  const { addError, removeError } = useAPIError();
  const { t } = useTranslation('errors');

  const statusResponse = (response: Response, responseJson: boolean, errorMessage?: string) => {
    if (response.status === 200) {
      removeError();
      if (responseJson) {
        return response.json();
      } else {
        return Promise.resolve();
      }
    }

    if (errorMessage) {
      addError(t(errorMessage), response);
    }
    return Promise.reject();
  };

  const apiUpdateWorkStatus = async (payload: UpdateEmployeeWorkStatusPayload): Promise<WorkStatus> => {
    const { employeeId, ...rest } = payload;
    const response = await cuttersFetch(API.UPDATE_EMPLOYEE_WORK_STATUS, {
      method: 'PUT',
      params: {
        employeeId,
      },
      body: JSON.stringify(rest),
    });

    return statusResponse(response, true);
    // TODO: Solve issue with duplicate events coming from SSE
    // addError(t('somethingWentWrong'), response);
  };

  const apiPatchProfile = async (id: string, patch: Operation[]): Promise<Employee> => {
    const response = await cuttersFetch(API.PATCH_EMPLOYEE_PROFILE, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      params: { id },
      body: JSON.stringify(patch),
    });

    return statusResponse(response, true, 'profileUpdateFailed');
  };

  const apiUpdateTransaction = async (payload: UpdateTransactionPayload): Promise<Transaction> => {
    const { id, ...rest } = payload;

    const response = await cuttersFetch(API.UPDATE_TRANSACTION, {
      method: 'PUT',
      params: { id },
      body: JSON.stringify(rest),
    });

    return statusResponse(response, true, 'failedToAddFeedback');
  };

  const apiAddTransaction = async (token: string, payload: AddTransactionPayload): Promise<Transaction[]> => {
    const response = await fetch(API.ADD_TRANSACTION, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  };

  const apiAddEmployeeFeedback = async (payload: AddEmployeeFeedbackPayload): Promise<void> => {
    const response = await cuttersFetch(API.SEND_EMPLOYEE_FEEDBACK, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return statusResponse(response, false, 'failedToAddFeedback');
  };

  const apiSendNotification = async (payload: SendNotificationPayload): Promise<Response> => {
    const { transactionId, ...rest } = payload;

    const response = await cuttersFetch(API.SEND_NOTIFICATION, {
      method: 'POST',
      params: { transactionId },
      body: JSON.stringify(rest),
    });

    return statusResponse(response, true, 'failedToSendNotification');
  };

  const apiUpdateSingleSalonInventoryProduct = async (
    payload: UpdateSingleSalonInventoryProductPayload,
  ): Promise<any> => {
    const { salonId, ...rest } = payload;

    const response = await cuttersFetch(API.UPDATE_SALON_INVENTORY, {
      method: 'POST',
      params: { salonId },
      baseUlr: BASE_APP_URL,
      body: JSON.stringify(rest),
    });

    return statusResponse(response, true, 'inventoryUpdateFailed');
  };

  const apiUpdateSalonInventory = async (payload: UpdateSalonInventoryPayload): Promise<any> => {
    const { salonId, ...rest } = payload;

    const response = await cuttersFetch(API.UPDATE_SALON_INVENTORY, {
      method: 'POST',
      params: { salonId },
      baseUlr: BASE_APP_URL,
      body: JSON.stringify(rest),
    });

    return statusResponse(response, true);
  };

  const apiCreateCutNote = async (payload: CutNotePayload): Promise<CutNote> => {
    const response = await cuttersFetch(API.CREATE_CUT_NOTE, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return statusResponse(response, true, 'failedToCreateCutNote');
  };

  const apiSendVoucher = async (payload: SendVoucherPayload): Promise<Response> => {
    const { transactionId } = payload;

    const res = await cuttersFetch(API.GET_TRANSACTION, {
      method: 'GET',
      params: { id: transactionId },
    });

    const transaction: Transaction = await res.json();
    // If the transaction has a reasonId, it was manually added and no voucher should be sent
    if (!transaction || (transaction?.reasonId && transaction?.reasonId > 0)) {
      addError(t('voucherWasNotSent'), { transaction: { id: transactionId, reasonId: transaction?.reasonId }});
      return Promise.reject();
    }

    const response = await cuttersFetch(API.SEND_VOUCHER, {
      method: 'POST',
      params: { transactionId },
    });

    return statusResponse(response, true, 'voucherWasNotSent');
  };

  const apiAddShiftPeriod = async (payload: AddShiftPeriodPayload): Promise<Shift> => {
    const response = await cuttersFetch(API.ADD_SHIFT_PERIOD, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return statusResponse(response, true, 'failedToAddShiftPeriod');
  };

  const apiCutNotesCustomer = async (ids: string[]): Promise<CutNote[]> => {
    const response = await cuttersFetch(API.CUT_NOTE_LIST_CUSTOMER, {
      method: 'POST',
      body: JSON.stringify({ ids }),
    });

    return statusResponse(response, true, 'failedToFetchCutNote');
  };

  const apiRemoveShiftPeriod = async (payload: RemoveShiftPeriodPayload): Promise<void> => {
    const response = await cuttersFetch(API.REMOVE_SHIFT_PERIOD, {
      method: 'DELETE',
      params: {
        employeeId: payload.employeeId,
        id: payload.periodId,
      },
    });

    return statusResponse(response, false, 'failedToRemoveShiftPeriod');
  };

  const apiScheduleBreak = async (payload: ScheduleBreakPayload): Promise<void> => {
    const response = await cuttersFetch(API.SCHEDULE_BREAK, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return statusResponse(response, false, 'failedToScheduleBreak');
  };

  const apiCancelScheduledBreak = async (payload: CancelScheduledBreakPayload): Promise<void> => {
    const response = await cuttersFetch(API.CANCEL_SCHEDULED_BREAK, {
      method: 'DELETE',
      params: payload,
    });

    return statusResponse(response, false, 'failedToCancelScheduledBreak');
  };

  return {
    apiUpdateWorkStatus,
    apiPatchProfile,
    apiUpdateTransaction,
    apiAddTransaction,
    apiAddEmployeeFeedback,
    apiSendNotification,
    apiUpdateSingleSalonInventoryProduct,
    apiUpdateSalonInventory,
    apiCreateCutNote,
    apiSendVoucher,
    apiAddShiftPeriod,
    apiRemoveShiftPeriod,
    apiScheduleBreak,
    apiCancelScheduledBreak,
    apiCutNotesCustomer,
  };
};
