import { useAuth0 } from '@auth0/auth0-react';
import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { API_BASE_URL } from 'utils/global-constants';

async function fetchWithAccessToken(url: string, accessToken: string) {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });

  return response.json();
}

export function useGet<Data>(path: string | null, options?: SWRConfiguration): SWRResponse<Data, Error> {
  const { getAccessTokenSilently } = useAuth0();

  return useSWR(
    path,
    async (key) => {
      const accessToken = await getAccessTokenSilently({ scope: 'read:users' });
      return fetchWithAccessToken(key, accessToken);
    },
    options,
  );
}
