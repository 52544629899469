import { useGet, useErrorHandler } from 'hooks';
import { Country } from 'models';

export function useCountries() {
  const { data, error } = useGet<Country[]>('/salons/countries/all', {
    revalidateOnFocus: false,
    dedupingInterval: 8 * 60 * 60 * 1000, // 8 hours deduping to make it static after first call for shift
  });

  useErrorHandler(data, error, 'failedToFetchCountries');

  return {
    countries: data,
    isLoading: !error && !data,
    error: error,
  };
}
