import { RootState, useAppDispatch } from '../store';
import {
  fetchQueue,
  addCustomerToQueue,
  removeCustomerFromQueue,
  selectCustomer,
  deselectCustomer,
  setCustomerInProgress,
  cleanUpQueueAfterHaircut,
  SalonWaitingTime,
  QueueItem,
} from '../store/queue/queueSlice';
import { useEventFeed } from './useEventFeed';
import { useAuth0 } from '@auth0/auth0-react';
import { AddTransactionPayload, UpdateTransactionPayload, useCurrentEmployeeWorkStatus, useHairdresser } from '@hooks';
import { QueueCustomerModel } from 'models';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useQueue = () => {
  const salonId: string = useSelector((state: any) => state.queue.salonId);
  const salonWaitingTime: SalonWaitingTime = useSelector((state: any) => state.queue.salonWaitingTime);
  const simulationTime: string = useSelector((state: any) => state.queue.simulationTime);
  const queueCustomers: QueueCustomerModel[] = useSelector((state: any) => state.queue.customers);
  const selectedQueueCustomer: null | QueueCustomerModel = useSelector((state: any) => state.queue.selectedCustomer);
  const customerInProgress: null | QueueCustomerModel = useSelector((state: any) => state.queue.customerInProgress);
  const pendingCustomers: QueueCustomerModel[] = useSelector((state: any) => state.queue.pendingCustomers);
  const { workStatus } = useCurrentEmployeeWorkStatus();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { employee } = useHairdresser();

  const employeeCustomers: QueueItem[] = useSelector((state: RootState) => state.queue.employeeCustomers);
  const employeeActiveCustomer: QueueCustomerModel | null= useSelector((state: RootState) => {
    const isServing = !!state.queue.employeeCustomers
      && state.queue.employeeCustomers.length > 0
      && !!state.queue.customerInProgress;

    return isServing ? state.queue.customerInProgress : null;
  });

  const employeeWaitingInLineCount: number = useSelector((state: RootState) => {
    if(!state.queue.employeeCustomers || state.queue.employeeCustomers.length === 0) {
      return 0;
    }

    return state.queue.employeeCustomers.filter((customer) => {
      return customer.data.state === 'waiting' && customer.data.employeeId !== employee?.id;
    }).length;
  });

  const otherEmployeeBreaks = useSelector((state: RootState) => {
    const customersServedByOthers = state.queue.customers.filter(customer => {
      return customer.employeeId !== employee?.id
        && customer.state === 'serving';
    });

    return state.queue.scheduledBreaks.filter(scheduledBreak => {
      return customersServedByOthers.find(customer => {
        return scheduledBreak.employeeId === `${customer.employeeId}`
          && scheduledBreak.transactionId === customer.transactionId;
      });
    });
  });

  useEventFeed(workStatus);

  useEffect(() => {
    const customerInProgress = queueCustomers.find((customer) => customer.employeeId === employee?.id) || null;
    dispatch(setCustomerInProgress(customerInProgress));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueCustomers, employee]);

  const fetchQueueAction = (salonId: any) => {
    dispatch(fetchQueue({ salonId, employeeId: employee?.id }));
  };

  const addCustomerToQueueAction = async (payload: AddTransactionPayload) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(addCustomerToQueue({ token, payload })).unwrap();
  };

  const removeCustomerFromQueueAction = async (payload: UpdateTransactionPayload) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(removeCustomerFromQueue({ token, payload })).unwrap();
  };

  const selectCustomerAction = (customer: QueueCustomerModel | null, asInProgress: boolean = false) => {
    dispatch(selectCustomer({ customer: customer, asInProgress: asInProgress }));
  };

  const deselectCustomerAction = () => {
    dispatch(deselectCustomer());
  };

  const cleanUpQueueAfterHaircutAction = (transactionId: string) => {
    dispatch(cleanUpQueueAfterHaircut(transactionId));
  };

  const setCustomerInProgressAction = (customer: QueueCustomerModel | null) => {
    dispatch(setCustomerInProgress(customer));
  };

  return {
    salonId,
    salonWaitingTime,
    simulationTime,
    queueCustomers,
    selectedQueueCustomer,
    fetchQueueAction,
    addCustomerToQueueAction,
    removeCustomerFromQueueAction,
    selectCustomerAction,
    deselectCustomerAction,
    cleanUpQueueAfterHaircutAction,
    setCustomerInProgressAction,
    customerInProgress,
    pendingCustomers,
    employeeCustomers,
    employeeActiveCustomer,
    employeeWaitingInLineCount,
    otherEmployeeBreaks,
  };
};
