import { NoshowCustomerModel } from '@models';
import { NoshowQueueStateModel } from './noshowQueueSlice';
import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { isToday } from 'date-fns';

interface AddNoshowPayload {
  customer: NoshowCustomerModel;
}

const filterAndSortNoshowCustomers = (customers: NoshowCustomerModel[]) => customers
  ?.filter((customer) => isToday(new Date(customer.createdAt)))
  .sort((a: NoshowCustomerModel, b: NoshowCustomerModel) => 
    a.createdAt > b.createdAt ? 1 : -1
  ) || [];

export const setNoshowQueue = (state: Draft<NoshowQueueStateModel>, newState: NoshowQueueStateModel) => {
  state.customers = filterAndSortNoshowCustomers(newState.customers);
  state.selectedNoshowCustomer = newState.selectedNoshowCustomer;
  state.salonId = newState.salonId;
};

export const addNoshowCustomerState = (state: Draft<NoshowQueueStateModel>, action: PayloadAction<AddNoshowPayload>) => {
  const serverNoshowCustomer = <NoshowCustomerModel> action.payload.customer;

  state.customers = filterAndSortNoshowCustomers([...state.customers, serverNoshowCustomer]);
};

export const removeNoshowCustomerState = (state: Draft<NoshowQueueStateModel>, action: PayloadAction<{ transactionId: string }>) => {
  state.customers = state.customers.filter((c: NoshowCustomerModel) => c.transactionId !== action.payload.transactionId);
  if (state.selectedNoshowCustomer?.transactionId === action.payload.transactionId) {
    state.selectedNoshowCustomer = null;
  }
}