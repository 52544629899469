import { theme } from '@cutters-digital/wig-js';
import { createStitches, createTheme, globalCss } from '@stitches/react';
import { normalize } from 'normalize-stitches/out';

createTheme(theme);

export const globalStyles = globalCss({
  ...normalize,
  '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
  '*:before': { margin: 0, padding: 0, boxSizing: 'border-box' },
  '*:after': { margin: 0, padding: 0, boxSizing: 'border-box' },
  'ul, ol': { listStyle: 'none' },
  body: { backgroundColor: '$black', margin: 0, color: '$white' },
  main: { minHeight: 'var(--app-height)' },
  button: { border: 0, outline: 0 },

  '@font-face': [
    {
      fontFamily: 'Brown-Regular',
      src: 'url("/fonts/Brown-Regular.woff") format("woff")',
    },
    {
      fontFamily: 'Brown-Bold',
      src: 'url("/fonts/Brown-Bold.woff") format("woff")',
    },
    {
      fontFamily: 'Brown-Bold-Alt',
      src: 'url("/fonts/Brown-Bold-Alt.woff") format("woff")',
    },
  ],
});

export const { styled, css } = createStitches({
  media: {
    bp1: '(min-width: 768px)',
    bp2: '(min-width: 1024px)',
  },
});
