import { API } from '@hooks';
import { AddNoshowCustomerModel, NoshowCustomerModel } from '@models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { urlWithParams } from '@utils/helpers';
import { API_BASE_URL } from 'utils/global-constants';

export interface FetchNoshowQueueResponse {
  customers: NoshowCustomerModel[];
}

export interface FetchNoshowQueuePayload {
  salonId: string | undefined;
}

export const thunkActions = {
  fetchNoshowQueue: createAsyncThunk(
    'noshowQueue/fetchNoshowQueue',
    async (data: { token: string; payload: FetchNoshowQueuePayload }): Promise<FetchNoshowQueueResponse | undefined> => {
      if (!data?.payload.salonId) {
        return;
      }
      const response = await fetch(urlWithParams(API_BASE_URL + API.GET_SALON_NOSHOW_QUEUE, { salonId: data.payload.salonId}), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${data.token}`,
        },
      });

      const res = await response.json();

      return Promise.resolve({
        customers: res?.[data.payload.salonId]
      });
    }),
  addNoshowCustomer: createAsyncThunk(
    'noshowQueue/addCustomerToQueue',
    async (data: { token: string; transactionId: string; payload: AddNoshowCustomerModel; }) => {
      const { token, transactionId, payload } = data;
      return apiAddNoshowCustomer(token, transactionId, payload);
    },
  ),
  deleteNoshowCustomer: createAsyncThunk(
    'noshowQueue/deleteNoshowCustomer',
    async (data: { token: string; transactionId: string; }) => {
      return apiDeleteNoshowCustomer(data.token, data.transactionId);
    },
  ),
  requeueNoshowCustomer: createAsyncThunk(
    'noshowQueue/requeueNoshowCustomer',
    async (data: { token: string; transactionId: string; }) => {
      const { token, transactionId } = data;
      return apiRequeueNoshowCustomer(token, transactionId);
    },
  ),
};

const apiAddNoshowCustomer = async (token: string, transactionId: string, payload: AddNoshowCustomerModel) => {
  const response = await fetch(urlWithParams(API_BASE_URL + API.NOSHOW_CUSTOMER, { transactionId }), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status >= 200 && response.status <= 202) {
    const data = await response.json();

    if (data) return { customer: { transactionId, ...payload } };
    
    return null;
  }

  throw new Error(response.statusText);
};

const apiDeleteNoshowCustomer = async (token: string, transactionId: string) => {
  const response = await fetch(urlWithParams(API_BASE_URL + API.NOSHOW_CUSTOMER, { transactionId }), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  if (response.status >= 200 && response.status <= 202) {
    const data = await response.json();
    if (data === true) return { transactionId };
    
    return null;
  }

  throw new Error(response.statusText);
};

const apiRequeueNoshowCustomer = async (token: string, transactionId: string) => {
  const response = await fetch(urlWithParams(API_BASE_URL + API.NOSHOW_CUSTOMER_MOVE, { transactionId }), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  if (response.status >= 200 && response.status <= 202) {
    return { transactionId };
  }

  throw new Error(response.statusText);
};