import { fetchBookings, setCustomerBookingDue, BookingsStateModel } from './bookingsSlice';
import { setBookings, removeBooking } from './bookingsStateFunctions';
import { ReducerBuilder } from 'store/types/storeTypes';
import { FetchBookingsResponse } from './bookingsThunks';
import { PayloadAction } from '@reduxjs/toolkit';
import { BookingCustomerModel } from '@models';

export const extraReducersBuilder =
  (initialState: BookingsStateModel) => (builder: ReducerBuilder<BookingsStateModel>) => {
    builder
      .addCase(
        fetchBookings.fulfilled,
        (state, action: PayloadAction<FetchBookingsResponse>) => setBookings(state, {...state, customers: action.payload?.bookings})
      )
      .addCase(fetchBookings.rejected, (state, action) => {
        state.error = action.error.message;
        setBookings(state, initialState);
      })
      .addCase(
        setCustomerBookingDue.fulfilled,
        (state, action: PayloadAction<BookingCustomerModel>) => {
          removeBooking(state, action);
        }
      )
  };
