import { useAppDispatch } from '../store';
import {
  fetchBookings,
  selectCustomer,
  deselectCustomer,
  setCustomerBookingDue
} from '../store/bookings/bookingsSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useHairdresser } from '@hooks';
import { BookingCustomerModel } from 'models';
import { useSelector } from 'react-redux';

export const useBookings = () => {
  const salonId: string = useSelector((state: any) => state.queue.salonId);
  const bookingsCustomers: BookingCustomerModel[] = useSelector((state: any) => state.bookings.customers);
  const selectedBookingCustomer: null | BookingCustomerModel = useSelector((state: any) => state.bookings.selectedBookingCustomer);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { employee } = useHairdresser();

  const fetchBookingsAction = async (salonId: any) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    dispatch(fetchBookings({ token, payload: { salonId, employeeId: employee?.id } }));
  };

  const selectCustomerAction = (customer: BookingCustomerModel | null, asInProgress: boolean = false) => {
    dispatch(selectCustomer({ customer: customer, asInProgress: asInProgress }));
  };

  const deselectCustomerAction = () => {
    dispatch(deselectCustomer());
  };

  const setCustomerBookingDueAction = async (id: string) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(setCustomerBookingDue({ token, id })).unwrap();
  };

  return {
    salonId,
    bookingsCustomers,
    selectedBookingCustomer,
    fetchBookingsAction,
    selectCustomerAction,
    deselectCustomerAction,
    setCustomerBookingDueAction
  };
};
