import { PayloadAction } from '@reduxjs/toolkit';
import { BookingsStateModel } from '../bookingsSlice';
import { BookingCustomerModel } from 'models';

interface SelectCustomerActionPayload {
  customer: BookingCustomerModel | null;
}

export const selectCustomer = (
  state: BookingsStateModel,
  action: PayloadAction<SelectCustomerActionPayload>
): void => {
  const { customer } = action.payload;
  state.selectedBookingCustomer = customer;
};
