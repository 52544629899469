import { useAppDispatch } from '../store';
import {
  fetchNoshowQueue,
  selectNoshowCustomer,
  deselectNoshowCustomer,
  addNoshowCustomer,
  deleteNoshowCustomer,
  requeueNoshowCustomer
} from '../store/noshowQueue/noshowQueueSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useHairdresser } from '@hooks';
import { AddNoshowCustomerModel, NoshowCustomerModel } from 'models';
import { useSelector } from 'react-redux';

export const useNoshowQueue = () => {
  const salonId: string = useSelector((state: any) => state.queue.salonId);
  const noshowCustomers: NoshowCustomerModel[] = useSelector((state: any) => state.noshowQueue.customers);
  const selectedNoshowCustomer: null | NoshowCustomerModel = useSelector((state: any) => state.noshowQueue.selectedNoshowCustomer);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { employee } = useHairdresser();

  const fetchNoshowQueueAction = async (salonId: any) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    dispatch(fetchNoshowQueue({ token, payload: { salonId, employeeId: employee?.id } }));
  };

  const selectCustomerAction = (customer: NoshowCustomerModel | null, asInProgress: boolean = false) => {
    dispatch(selectNoshowCustomer({ customer, asInProgress }));
  };

  const deselectCustomerAction = () => {
    dispatch(deselectNoshowCustomer());
  };

  const addNoshowCustomerAction = async (transactionId: string, payload: AddNoshowCustomerModel) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(addNoshowCustomer({ token, transactionId, payload })).unwrap();
  };

  const removeNoshowCustomerAction = async (transactionId: string) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(deleteNoshowCustomer({ token, transactionId })).unwrap();
  };

  const requeueNoshowCustomerAction = async (transactionId: string) => {
    const token = await getAccessTokenSilently({ scope: 'read:users' });
    return dispatch(requeueNoshowCustomer({ token, transactionId })).unwrap();
  };

  return {
    salonId,
    noshowCustomers,
    selectedNoshowCustomer,
    fetchNoshowQueueAction,
    selectCustomerAction,
    deselectCustomerAction,
    addNoshowCustomerAction,
    removeNoshowCustomerAction,
    requeueNoshowCustomerAction
  };
};
