import * as React from 'react';
import { createPortal } from 'react-dom';

export const Error: React.FC = ({ children }) => {
  const ref = React.useRef();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    ref.current = document.querySelector('#errorPortal');
    setMounted(true);
  }, []);

  return mounted ? createPortal(children, ref.current) : null;
};
