import { hairdressersReducers } from './hairdressersReducers';
import { createSlice } from '@reduxjs/toolkit';
import { Hairdresser } from 'models';

export interface HairdressersStateModel {
  hairdressers: Hairdresser[];
  currentHairdresser: Hairdresser | undefined;
  error: any;
};

const initialState: HairdressersStateModel = {
  hairdressers: [],
  currentHairdresser: undefined,
  error: null,
};

export const hairdressersSlice = createSlice({
  name: 'hairdressers',
  initialState,
  reducers: hairdressersReducers,
});

export const { setHairdressers, setCurrentHairdresser } = {
  ...hairdressersSlice.actions,
};

export default hairdressersSlice.reducer;
