import { StateReducers } from '../types/storeTypes';
import {
  cleanUpQueueAfterHaircut,
  deselectCustomer,
  selectCustomer,
  setCustomerInProgress,
  updateQueue,
  setScheduledBreaks,
} from './actions';
import { QueueStateModel } from './queueSlice';

export const queueReducers: StateReducers<QueueStateModel> = {
  selectCustomer,
  deselectCustomer,
  setCustomerInProgress,
  updateQueue,
  cleanUpQueueAfterHaircut,
  setScheduledBreaks,
};
