import { useAppDispatch } from '../store';
import { updateQueue, setScheduledBreaks } from '../store/queue/queueSlice';
import { useCutters } from './cutters';
import { useCurrentEmployee } from './employee/useCurrentEmployee';
import { WorkStatus } from '@models';
import { ServerEventSource } from '@utils/serverEventSource';
import { useEffect, useState } from 'react';
import { addBooking, updateBooking, removeBooking } from 'store/bookings/bookingsSlice';
import { addNoshowCustomerState, removeNoshowCustomerState } from 'store/noshowQueue/noshowQueueSlice';
import { setHairdressers } from 'store/hairdressers/hairdressersSlice';
import { APP_QUEUE_URL } from 'utils/global-constants';

export function useEventFeed(workStatus: WorkStatus | undefined) {
  const [queue, setQueue] = useState(null);
  const [retry, setRetry] = useState(0);
  const dispatch = useAppDispatch();
  const { employee } = useCurrentEmployee();
  const { takeBreak } = useCutters();

  useEffect(() => {
    const { salonId, state, employeeId } = workStatus ? workStatus : { salonId: null, state: null, employeeId: null };
    if (salonId && state === 'work' && employeeId) {
      ServerEventSource.connect(`${APP_QUEUE_URL}${salonId}`).onMessage(({ queue, hairdressers, scheduledBreak, booking_added, booking_changed, booking_deleted, noshow_added, noshow_deleted }) => {
        if (!!queue) {
          dispatch(updateQueue({ queue, employeeId }));
        }

        if (booking_added) {
          dispatch(addBooking({ booking: booking_added.payload?.booking }));
        }

        if (booking_changed) {
          dispatch(updateBooking({ booking: booking_changed.payload?.booking }));
        }

        if (booking_deleted) {
          dispatch(removeBooking({ id: booking_deleted.payload?.id}));
        }

        if (noshow_added) {
          dispatch(addNoshowCustomerState({ customer: noshow_added }));
        }

        if (noshow_deleted) {
          dispatch(removeNoshowCustomerState({ transactionId: noshow_deleted.transactionId}));
        }

        if (!!queue && !!queue.scheduledBreaks && queue.scheduledBreaks.length > 0) {
          dispatch(setScheduledBreaks({ scheduledBreaks: queue.scheduledBreaks }));
        }

        if (scheduledBreak && employee && scheduledBreak.employeeId === `${employeeId}`) {
          takeBreak(scheduledBreak.duration);
        }

        if (hairdressers.length > 0) {
          dispatch(setHairdressers({ hairdressers, salonId }));
        }
      });

      return () => {
        setRetry(retry + 1);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workStatus, retry, setRetry, setQueue]);

  return { queue, setQueue, setRetry };
}
