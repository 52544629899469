import { styled } from '../stitches.config';
import { Error } from './Portals/Error';
import { Text } from '@cutters-digital/wig-js';
import * as React from 'react';

const StyledErrorMessageContainer = styled('div', {
  position: 'fixed',
  zIndex: '1',
  padding: '$2',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '$error',
  borderRadius: '$2',
});

type Props = {
  message: string;
};

export const ErrorMessage: React.FC<Props> = (props) => {
  const { message } = props;
  return (
    <Error>
      <StyledErrorMessageContainer>
        <Text>{message}</Text>
      </StyledErrorMessageContainer>
    </Error>
  );
};
