import { thunkActions } from './noshowQueueThunks';
import { NoshowCustomerModel } from '@models';
import { createSlice } from '@reduxjs/toolkit';
import { noshowQueueReducers } from './noshowQueueReducers';
import { extraReducersBuilder } from './noshowQueueExtraReducers';

export type NoshowQueueStateModel = {
  salonId: string | null;
  customers: NoshowCustomerModel[];
  selectedNoshowCustomer: NoshowCustomerModel | null;
  error: string | null;
};

const initialState: NoshowQueueStateModel = {
  salonId: null,
  customers: [],
  selectedNoshowCustomer: null,
  error: null,
};

export const noshowQueueSlice = createSlice({
  name: 'noshowQueue',
  initialState,
  reducers: noshowQueueReducers,
  extraReducers: extraReducersBuilder(initialState),
});

export const {
  fetchNoshowQueue,
  selectNoshowCustomer,
  deselectNoshowCustomer,
  addNoshowCustomer,
  deleteNoshowCustomer,
  requeueNoshowCustomer,
  addNoshowCustomerState,
  removeNoshowCustomerState
}: any = {
  ...noshowQueueSlice.actions,
  ...thunkActions,
};

export default noshowQueueSlice.reducer;
