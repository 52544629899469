import { QueueStateModel } from '../queueSlice';
import { forceCustomerStateUpdate } from '../queueStateFunctions';
import { current, PayloadAction } from '@reduxjs/toolkit';
import { QueueCustomerModel } from 'models';

export const setCustomerInProgress = (
  state: QueueStateModel,
  action: PayloadAction<QueueCustomerModel>
) => {
  const customer = action.payload;

  if (customer) {
    const customerInMainQueue = current(state).customers.find((item) => item.transactionId === customer?.transactionId);
    const customerInPendingQueue = current(state).pendingCustomers.find((item) => item.transactionId === customer.id);
    const customerInQueue: any = customerInMainQueue || customerInPendingQueue;

    if (customerInQueue) {
      state.customerInProgress = { ...customerInQueue };
    }

    if (customerInPendingQueue) {
      forceCustomerStateUpdate(state, customerInPendingQueue.id);
    }
  } else {
    state.customerInProgress = null;
  }
};
