import { StateReducers } from '../types/storeTypes';
import {
  deselectCustomer,
  selectCustomer,
} from './actions';
import { BookingsStateModel } from './bookingsSlice';
import { addBooking, updateBooking, removeBooking } from './bookingsStateFunctions';

export const bookingReducers: StateReducers<BookingsStateModel> = {
  selectCustomer,
  deselectCustomer,
  addBooking,
  updateBooking,
  removeBooking
};
