import { useGet, useErrorHandler, useHairdresser } from 'hooks';
import { Shift } from 'models';

export const useCurrentEmployeeWorkShifts = () => {
  const { employee } = useHairdresser();
  const { data, error } = useGet<Shift>(employee ? `/shifts/employee/${employee.id}` : null);

  useErrorHandler(data, error, 'failedToFetchShifts');

  return {
    shift: data,
    isLoading: !error && !data,
    error: error,
  };
};
