import { sanityClient } from 'lib/sanity.server';
import useSWR from 'swr';

const sanityFetcher = async (query: string, locale: string) => {
  return sanityClient.fetch(query, { locale });
};

export const useSanity = <Data>(query: string, locale = 'nb') => {
  return useSWR<Data>([query, locale], sanityFetcher, {
    revalidateOnFocus: false,
  });
};
