import { CutNoteDetails, CutNoteStateModel } from '../cutNoteSlice';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetCutNoteDetailsActionPayload {
  details: CutNoteDetails;
}

export const setCutNoteDetails = (state: CutNoteStateModel, action: PayloadAction<SetCutNoteDetailsActionPayload>) => {
  state.data.details = action.payload.details;
};
