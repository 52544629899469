import { PayloadAction } from '@reduxjs/toolkit';
import { QueueStateModel } from '../queueSlice';
import { QueueCustomerModel } from 'models';

interface SelectCustomerActionPayload {
  customer: QueueCustomerModel | null;
  asInProgress: boolean;
}

export const selectCustomer = (
  state: QueueStateModel,
  action: PayloadAction<SelectCustomerActionPayload>
): void => {
  const { customer, asInProgress } = action.payload;
  state.selectedCustomer = customer;

  if (asInProgress) {
    const customerClone = { ...customer };
    customerClone.state = 'serving';
    state.selectedCustomer = customerClone;
  }
};
