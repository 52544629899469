export const API = {
  // GET END POINTS
  GET_CURRENT_EMPLOYEE: '/employees/current',
  GET_EMPLOYEE_SHIFT: '/shifts/employee/{id}',
  GET_EMPLOYEE_WORK_STATUS: '/workstatuses/employees/{id}',
  GET_PENDING_BOOKINGS: '/booking/pending/{salonId}',
  // OTHER END POINTS
  PATCH_EMPLOYEE_PROFILE: '/employees/profiles/{id}',
  UPDATE_EMPLOYEE_WORK_STATUS: '/workstatuses/employees/{employeeId}',
  GET_SALON_CUSTOMER_QUEUE: '/customerqueue/{salonId}',
  GET_TRANSACTION: '/transactions/{id}',
  UPDATE_TRANSACTION: '/transactions/{id}',
  ADD_TRANSACTION: '/transactions',
  SEND_EMPLOYEE_FEEDBACK: '/employees/feedback',
  SEND_NOTIFICATION: '/transactions/{transactionId}/notify',
  UPDATE_SALON_INVENTORY: '/api/inventory/{salonId}',
  CREATE_CUT_NOTE: '/cutnotes',
  CUT_NOTE_LIST_CUSTOMER: '/cutnotes/list',
  SEND_VOUCHER: '/voucher/{transactionId}',
  ADD_SHIFT_PERIOD: '/shifts/employee',
  REMOVE_SHIFT_PERIOD: '/shifts/employee/{employeeId}/periods/{id}',
  SCHEDULE_BREAK: '/customerqueue/scheduledBreak',
  CANCEL_SCHEDULED_BREAK: '/customerqueue/scheduledBreak/{id}',
  START_BOOKING: '/booking/start/{bookingId}',
  GET_SALON_NOSHOW_QUEUE: '/customerqueue/noshowqueue/salons/{salonId}',
  NOSHOW_CUSTOMER: '/customerqueue/noshowqueue/{transactionId}',
  NOSHOW_CUSTOMER_MOVE: '/customerqueue/noshowqueue/move/{transactionId}'
};
