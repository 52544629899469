import { Employee } from '../models';
import { useAuth0 } from '@auth0/auth0-react';
import { useGet, useErrorHandler } from 'hooks';
import { SWRConfiguration } from 'swr';

export const useHairdresser = () => {
  const { isAuthenticated } = useAuth0();

  const defaultOptions: SWRConfiguration = {
    dedupingInterval: 30 * 1000,
  };

  const { data: employee, error } = useGet<Employee>(isAuthenticated ? '/employees/current' : null, defaultOptions);

  useErrorHandler(employee, error, 'failedToFetchCurrentEmployee');

  return {
    employee,
    error,
    isLoading: !error && !employee,
  };
};
