import { useGet, useErrorHandler, useHairdresser } from 'hooks';
import { FeedbackRating } from 'models';

export const useEmployeeCustomerFeedbackRating = (startDate: string, endDate: string) => {
  const { employee } = useHairdresser();
  const { data, error } = useGet<FeedbackRating>(
    employee ? `/employees/${employee.id}/rating?startDate=${startDate}&endDate=${endDate}` : null,
  );

  useErrorHandler(data, error, 'failedToFetchFeedbackRating');

  return {
    feedback: data,
    isLoading: !error && !data,
    error: error,
  };
};
