import * as React from 'react';

function getNetworkConnection() {
  return navigator.connection || navigator.mozConnection || navigator.webkitConnection || null;
}

export const useNetwork = () => {
  const [state, setState] = React.useState<{
    since: undefined | string;
    online: boolean;
  }>({
    since: undefined,
    online: true,
  });

  React.useEffect(() => {
    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };

    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };

    const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
      }));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    const connection = getNetworkConnection();

    connection?.addEventListener('change', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      connection?.removeEventListener('change', handleConnectionChange);
    };
  }, []);
  return state;
};
