import { PayloadAction } from '@reduxjs/toolkit';
import { CutNoteStateModel, DetailValueType } from '../cutNoteSlice';

interface SetSelectedCutNoteIdActionPayload {
  key: string;
  value: DetailValueType[];
}

export const setSelectedCutNoteId = (
  state: CutNoteStateModel,
  action: PayloadAction<SetSelectedCutNoteIdActionPayload>,
) => {
  const { selectedCutNoteId } = action.payload;
  state.selectedCutNoteId = selectedCutNoteId;
};
