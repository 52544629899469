import { CutNote } from '@models';
import { useGet, useErrorHandler } from 'hooks';


export const useCutNote = (cutNoteId: string | null | undefined) => {
  const { data, error } = useGet<CutNote>(cutNoteId ? `/cutnotes/${cutNoteId}` : null);

  useErrorHandler(data, error, 'failedToFetchCutNote');

  return {
    cutNote: data,
    isLoading: !error && !data,
    error: error,
  };
};
