import { addCustomerToQueue, fetchQueue, QueueStateModel, removeCustomerFromQueue } from './queueSlice';
import { forceCustomerStateUpdate, setInitialQueue, setQueue } from './queueStateFunctions';
import { FetchQueueResponse } from './queueThunks';
import { PayloadAction } from '@reduxjs/toolkit';
import { Transaction } from 'models';
import { ReducerBuilder } from 'store/types/storeTypes';

export const extraReducersBuilder =
  (initialState: QueueStateModel) => (builder: ReducerBuilder<QueueStateModel>) => {
    builder
      .addCase(
        fetchQueue.fulfilled,
        (state, action: PayloadAction<FetchQueueResponse>) => setQueue(state, action.payload)
      )
      .addCase(fetchQueue.rejected, (state, action) => {
        state.error = action.error.message;
        setInitialQueue(state, initialState);
      })
      .addCase(addCustomerToQueue.fulfilled, (state, action) => {
        action.payload.forEach((transaction: Transaction) => {
          transaction.products = [];
          transaction.transactionId = transaction.id;
        });
        state.pendingCustomers = [...state.pendingCustomers, ...action.payload];
      })
      .addCase(addCustomerToQueue.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(removeCustomerFromQueue.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.selectedCustomer = null;
        forceCustomerStateUpdate(state, id);
      })
      .addCase(removeCustomerFromQueue.rejected, (state, action) => {
        state.error = action.error.message;
      });
  };
