import { QueueItem, QueueItemType, QueueStateModel } from './queueSlice';
import { CustomerQueueServerModel, QueueCustomerModel } from 'models';
import { current, Draft } from '@reduxjs/toolkit';

export const forceCustomerStateUpdate = (
  state: Draft<QueueStateModel>,
  transactionId: string,
  customerState: string = 'waiting',
) => {
  const customerIndex = current(state).customers.findIndex((customer) => customer.transactionId === transactionId);

  const pendingCustomerIndex = current(state).pendingCustomers.findIndex(
    (customer) => customer.transactionId === transactionId,
  );

  const customers = [...(<QueueCustomerModel[]>current(state).customers)];
  const pendingCustomers = [...(<QueueCustomerModel[]>current(state).pendingCustomers)];
  let customerClone;

  if (customerIndex !== -1) {
    customerClone = getPendingCustomerObject(customers[customerIndex], customerState);
    customers[customerIndex] = customerClone;
    state.customers = customers;
    return;
  }

  if (pendingCustomerIndex !== -1) {
    customerClone = getPendingCustomerObject(pendingCustomers[pendingCustomerIndex], customerState);
    pendingCustomers[pendingCustomerIndex] = customerClone;
    state.pendingCustomers = pendingCustomers;
    return;
  }
};

export const getPendingCustomerObject = (customer: any, customerState: string) => {
  const clone = { ...customer };
  clone.state = customerState;
  clone.employeeId = 0;
  clone.pending = true;
  return clone;
};

interface SetQueuePayload {
  queue: CustomerQueueServerModel;
  employeeId: number;
}

export const setQueue = (state: Draft<QueueStateModel>, payload: SetQueuePayload) => {
  state.customers = payload.queue.customers;
  state.scheduledBreaks = payload.queue.scheduledBreaks ? payload.queue.scheduledBreaks : [];
  state.simulationTime = payload.queue.simulationTime;
  state.salonId = payload.queue.salonId;
  state.salonWaitingTime = {
      waitTime: payload.queue.salonWaitingTime.waittime,
      state: payload.queue.salonWaitingTime.state,
  };

  setEmployeeCustomers(state, { employeeId: payload.employeeId });
}

interface SetEmployeeCustomersPayload {
  employeeId: number;
}

export const setEmployeeCustomers = (state: Draft<QueueStateModel>, payload: SetEmployeeCustomersPayload) => {
  if (state.customers.length === 0) {
    state.employeeCustomers = [];
  }

  const employeeCustomers: QueueItem[] = state.customers
    .filter((customer) => customer.employeeId === payload.employeeId || customer.employeeId === 0)
    .sort((a, b) => {
      if (a.state === 'serving' && b.state !== 'serving') {
        return -1;
      }
      if (a.state !== 'serving' && b.state === 'serving') {
        return 1;
      }
      return 0;
    })
    .map((customer: QueueCustomerModel) => {
      const item: QueueItem = {
        type: QueueItemType.Customer,
        data: { ...customer },
      }
      return item;
    });

    state.employeeCustomers = employeeCustomers;
}

export const setInitialQueue = (state: Draft<QueueStateModel>, initialState: QueueStateModel) => {
  state.customers = initialState.customers;
  state.employeeCustomers = initialState.employeeCustomers;
  state.simulationTime = initialState.simulationTime;
  state.salonId = initialState.salonId;
  state.salonWaitingTime = initialState.salonWaitingTime;
};
