import { Hairdresser, ScheduledBreakDueModel } from '@models';

type ServerEventSourceResponse = {
  hairdressers: Hairdresser[],
  queue: any,
  scheduledBreak: ScheduledBreakDueModel | undefined
  booking_added: any,
  booking_changed: any,
  booking_deleted: any,
  noshow_added: any,
  noshow_deleted: any,
};

type ServerEventSourceCallback = (data: ServerEventSourceResponse) => ServerEventSourceResponse | void;

export class ServerEventSource {
  static instance: ServerEventSource;
  static eventSources: EventSource[] = [];

  private eventSource: EventSource;
  private url: string;
  private queueEvent = 'queue';
  private hairdressersEvent = 'hairdressers';
  private scheduledBreakEvent = 'scheduledBreak';
  private booking_addedEvent = 'booking_added';
  private booking_changedEvent = 'booking_changed';
  private booking_deletedEvent = 'booking_deleted';
  private noshow_addedEvent = 'noshow_added';
  private noshow_deletedEvent = 'noshow_deleted';

  private callback: ServerEventSourceCallback = () => this.initialResponse;
  private initialResponse: ServerEventSourceResponse = {
    hairdressers: [],
    queue: undefined,
    scheduledBreak: undefined,
    booking_added: undefined,
    booking_changed: undefined,
    booking_deleted: undefined,
    noshow_added: undefined,
    noshow_deleted: undefined
  }

  constructor(url: string) {
    console.log('Connecting to SSE...');
    this.url = url;
    this.initEventSource(url);
  }

  static connect(url: string): ServerEventSource {
    if (this.instance && this.instance.url !== undefined && this.instance.url !== url) {
      this.instance.reconnect(url);
    }
    return this.instance || (this.instance = new this(url));
  }

  private messageEventListener = (event: Event) => {
    const messageEvent = event as MessageEvent;
    const data = JSON.parse(messageEvent.data);

    if(!this.callback || typeof this.callback !== 'function') {
      return;
    }

    this.callback({
      queue: event.type === this.queueEvent ? data : this.initialResponse.queue,
      hairdressers: event.type === this.hairdressersEvent ? data : this.initialResponse.hairdressers,
      scheduledBreak: event.type === this.scheduledBreakEvent ? data : this.initialResponse.scheduledBreak,
      booking_added: event.type === this.booking_addedEvent ? data : this.initialResponse.booking_added,
      booking_changed: event.type === this.booking_changedEvent ? data : this.initialResponse.booking_changed,
      booking_deleted: event.type === this.booking_deletedEvent ? data : this.initialResponse.booking_deleted,
      noshow_added: event.type === this.noshow_addedEvent ? data : this.initialResponse.noshow_added,
      noshow_deleted: event.type === this.noshow_deletedEvent ? data : this.initialResponse.noshow_deleted,
    });
  };

  private initEventSource(url: string) {
    this.eventSource = new EventSource(url);
    this.addListeners();
    ServerEventSource.eventSources.push(this.eventSource);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private connectionEventListener = (event: Event) => {
    console.log('SSE connected');
  };

  private errorEventListener = (event: Event) => {
    console.log('SSE error');
    console.log(event);
    this.reconnect(this.url);
  };

  public onMessage = (callback: ServerEventSourceCallback) => {
    this.callback = callback;
  };

  static disconnect() {
    if (this.instance) {
      this.instance.closeConnections();
    }
  }

  private reconnect(url: string) {
    this.closeConnections();
    this.removeListeners();
    this.eventSource.close();

    console.log('Reconnecting to SSE...');
    this.initEventSource(url);
  }

  private closeConnections() {
    ServerEventSource.eventSources.forEach((eventSource) => {
      eventSource.removeEventListener(this.queueEvent, this.messageEventListener);
      eventSource.removeEventListener('open', this.connectionEventListener);
      eventSource.removeEventListener('error', this.errorEventListener);
      eventSource.close();
      ServerEventSource.eventSources.splice(ServerEventSource.eventSources.indexOf(eventSource), 1);
    });
    console.log('SSE connection closed');
  }

  private addListeners() {
    this.eventSource.addEventListener(this.scheduledBreakEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.hairdressersEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.queueEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.booking_addedEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.booking_changedEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.booking_deletedEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.noshow_addedEvent, this.messageEventListener);
    this.eventSource.addEventListener(this.noshow_deletedEvent, this.messageEventListener);
    this.eventSource.addEventListener('open', this.connectionEventListener);
    this.eventSource.addEventListener('error', this.errorEventListener);
  }

  private removeListeners() {
    this.eventSource.removeEventListener(this.scheduledBreakEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.hairdressersEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.queueEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.booking_addedEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.booking_changedEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.booking_deletedEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.noshow_addedEvent, this.messageEventListener);
    this.eventSource.removeEventListener(this.noshow_deletedEvent, this.messageEventListener);
    this.eventSource.removeEventListener('open', this.connectionEventListener);
    this.eventSource.removeEventListener('error', this.errorEventListener);
  }
}
