import { RootState } from '../../store';
import { useSelector } from 'react-redux';

export const useCurrentEmployeeBreaks = (employeeId: number | undefined) => {
  const scheduledBreaks = useSelector((state: RootState) => {
    if(!employeeId) {
      return [];
    }
    return state.queue.scheduledBreaks.filter(scheduledBreak => scheduledBreak.employeeId === `${employeeId}`);
  });


  return { scheduledBreaks };
};
