import { setCurrentHairdresser } from 'store/hairdressers/hairdressersSlice';
import { RootState, useAppDispatch } from '../../store';
import { useHairdresser } from '../useHairdresser';
import { Hairdresser } from '../../models';
import { useSelector } from 'react-redux';

export const useCurrentEmployee = () => {
  const dispatch = useAppDispatch();
  const { employee: loggedInEmployee } = useHairdresser();

  const currentHairdresserSet: boolean = useSelector((state: RootState) => {
    return state.hairdressers.currentHairdresser !== undefined
      && state.hairdressers.currentHairdresser.id === employee?.id;
  });

  const employee: Hairdresser | undefined = useSelector((state: RootState) => {
    return state.hairdressers.hairdressers.find(
      (hairdresser: Hairdresser) => loggedInEmployee && hairdresser.id === loggedInEmployee.id
    );
  });

  if(!!loggedInEmployee && !currentHairdresserSet && !!employee) {
      dispatch(setCurrentHairdresser({ hairdresser: employee }));
  }

  return { employee };
};
