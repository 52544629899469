import { useHairdresser, useSanity } from '@hooks';
import { useState, useEffect } from 'react';

export const useFeature = (feature: string) => {
  const query = '*[_type == "feature"]';
  const { data } = useSanity(query);
  const { employee } = useHairdresser();
  const [featureEnabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!data || !employee) {
      return;
    }
    const featureData = data?.find(({ name }) => name.includes(feature));
    const isEnabled = featureData?.enabled || featureData?.users?.includes(employee.email);
    setEnabled(isEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, employee]);

  return featureEnabled;
};
