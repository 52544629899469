import { useGet, useErrorHandler, useHairdresser } from '@hooks';
import { HairCuts } from '@models';

export const useEmployeeHaircuts = (year: number, month?: number, day?: number) => {
  const { employee } = useHairdresser();
  let endPoint = `/employees/${employee.id}/haircuts/${year}`;

  if (month && day && day > 100) {
    const startDate = new Date(year, month - 1, day - 100);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    if (startDate.getMonth() === endDate.getMonth()) {
      endPoint = `/employees/${employee.id}/haircuts/${year}/${month}`;
    }
  } else if (month && day) {
    endPoint = `/employees/${employee.id}/haircuts/${year}/${month}/${day}`;
  } else if (month) {
    endPoint = `/employees/${employee.id}/haircuts/${year}/${month}`;
  }

  let { data, error } = useGet<HairCuts>(employee ? endPoint : null);
  useErrorHandler(data, error, 'failedToFetchEmployeeStats');

  return {
    haircuts: data,
    isLoading: !error && !data,
    error: error,
  };
};
