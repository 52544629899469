import { useGet, useErrorHandler } from 'hooks';
import { Salon } from 'models';

interface SalonsResponse {
  salons: Salon[] | undefined;
  isLoading: boolean;
  error?: any;
}

export const useSalons = (): SalonsResponse => {
  const { data, error } = useGet<Salon[]>('/salons/?includeDetails=false', {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useErrorHandler(data, error, 'failedToFetchSalons');

  return {
    salons: data,
    isLoading: !error && !data,
    error: error,
  };
};
