import { useGet, useErrorHandler } from 'hooks';
import { Transaction } from 'models';

export const useCustomerTransactions = (customerId: string) => {
  const { data, error } = useGet<Transaction[]>(`/transactions/customer/${customerId}?includeDetails=true`);

  useErrorHandler(data, error, 'failedToFetchCustomerTransactions');

  return {
    transactions: data,
    isLoading: !error && !data,
    error: error,
  };
};
