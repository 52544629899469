import { CuttersManager, useCuttersManager } from 'hooks';
import * as React from 'react';

export const CuttersContext = React.createContext<CuttersManager>({} as CuttersManager);

export const CuttersProvider: React.FC = (props) => {
  const { children } = props;

  return (
    <CuttersContext.Provider
      value={{
        ...useCuttersManager(),
      }}>
      {children}
    </CuttersContext.Provider>
  );
};
