import { cutNoteReducers } from './cutNoteReducers';
import { Gender } from '@components/CutNote';
import { createSlice } from '@reduxjs/toolkit';
import { CutNote } from '@models';

export type DetailValueType = string | number | boolean;
export type CutNoteDetails = { [key: string]: DetailValueType[] };

export interface CutNoteStateModel {
  data: {
    details: CutNoteDetails;
    gender?: Gender;
  };
  resetCount: number;
  dirty: boolean;
  rejected: boolean;
  name: string;
  allCutNotes: CutNote[];
  selectedCutNoteId: string;
}

const initialState: CutNoteStateModel = {
  data: {
    details: {},
    gender: undefined,
  },
  resetCount: 0,
  dirty: false,
  rejected: false,
  name: '',
  allCutNotes: [],
  selectedCutNoteId: '',
};

export const cutNoteSlice = createSlice({
  name: 'cutNote',
  initialState,
  reducers: cutNoteReducers,
});

export const {
  setCutNote,
  setCutNoteDetails,
  setResetCount,
  resetCutNoteDetails,
  calculateFormState,
  rejectCutNote,
  allowCutNote,
  setCutNoteGender,
  setCutNoteName,
  setAllCutNotes,
  setSelectedCutNoteId,
} = {
  ...cutNoteSlice.actions,
};

export default cutNoteSlice.reducer;
