import { PayloadAction } from '@reduxjs/toolkit';
import { NoshowQueueStateModel } from '../noshowQueueSlice';
import { NoshowCustomerModel } from 'models';

interface SelectCustomerActionPayload {
  customer: NoshowCustomerModel | null;
}

export const selectNoshowCustomer = (
  state: NoshowQueueStateModel,
  action: PayloadAction<SelectCustomerActionPayload>
): void => {
  const { customer } = action.payload;
  state.selectedNoshowCustomer = customer;
};
