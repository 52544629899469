import { QueueStateModel } from '../queueSlice';
import { setQueue } from '../queueStateFunctions';
import { current, PayloadAction } from '@reduxjs/toolkit';
import { CustomerQueueServerModel } from 'models';

interface UpdateQueuePayload {
  queue: CustomerQueueServerModel;
  employeeId: number;
}

export const updateQueue = (state: QueueStateModel, action: PayloadAction<UpdateQueuePayload>) => {
  const serverQueue = <CustomerQueueServerModel>action.payload.queue;

  const selectedCustomerIsRemoved =
    state.selectedCustomer &&
    !serverQueue.customers.find((c) => c.transactionId === state.selectedCustomer?.transactionId);

  if (selectedCustomerIsRemoved) {
    state.selectedCustomer = null;
  }

  const pendingCustomersInQueue = action.payload.queue.customers.filter((customer) =>
    state.pendingCustomers.find((item) => item.transactionId === customer.transactionId),
  );
  state.pendingCustomers = current(state).pendingCustomers.filter(
    (item) => !pendingCustomersInQueue.find((customer) => customer.transactionId === item.transactionId),
  );

  setQueue(state, { queue: action.payload.queue, employeeId: action.payload.employeeId });
};
