import { CutNoteStateModel } from '../cutNoteSlice';
import { Gender } from '@components/CutNote';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetCutNoteGenderActionPayload {
  gender: Gender;
}

export const setCutNoteGender = (
  state: CutNoteStateModel,
  action:PayloadAction<SetCutNoteGenderActionPayload>
): void => {
  state.data.gender = action.payload.gender;
};
