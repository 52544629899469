import { useAPIError } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

export const useErrorHandler = (data: any, error: any, translationKey: string) => {
  const { addError, removeError } = useAPIError();
  const { t } = useTranslation('errors');

  // Do not remove this comment. Used for i18next-parser
  // https://github.com/i18next/i18next-parser#caveats
  // t('failedToFetchCountries')
  // t('failedToFetchCurrentEmployee')
  // t('failedToFetchCustomer')
  // t('failedToFetchCustomerTransactions')
  // t('failedToFetchCutNote')
  // t('failedToFetchEmployeeStats')
  // t('failedToFetchFeedbackRating')
  // t('failedToFetchQueue')
  // t('failedToFetchSalon')
  // t('failedToFetchSalons')
  // t('failedToFetchWorkStatus')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (error ? addError(t(translationKey), error) : removeError()), [error, data]);
};
