import { fetchPricerules, PricerulesStateModel } from './priceruleSlice';
import { setPricerules } from './priceruleStateFunctions';
import { ReducerBuilder } from 'store/types/storeTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { PriceRule } from '@models';

export const extraReducersBuilder =
  (initialState: PricerulesStateModel) => (builder: ReducerBuilder<PricerulesStateModel>) => {
    builder
      .addCase(
        fetchPricerules.fulfilled,
        (state, action: PayloadAction<PriceRule[]>) => setPricerules(state, {...state, priceRules: action.payload?.priceRules, couponCode: action.payload?.couponCode})
      )
      .addCase(fetchPricerules.rejected, (state, action) => {
        state.error = action.error.message;
        setPricerules(state, initialState);
      })
  };