import { APIErrorProvider } from '../providers/APIErrorProvider';
import { CuttersProvider } from '../providers/CuttersProvider';
import store from '../store';
import { Auth0Provider } from '@auth0/auth0-react';
import { APIErrorNotification } from '@components/APIErrorNotification';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { ErrorMessage } from '@components/ErrorMessage';
import { globalStyles } from '@stitches';
import { useNetwork } from 'hooks';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import * as React from 'react';
import { Provider } from 'react-redux';

const onRedirectCallback = (appState: any) => {
  Router.replace(appState?.returnTo ? appState.returnTo : window.location.pathname);
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { online } = useNetwork();
  const { t } = useTranslation('common');

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  React.useEffect(() => {
    appHeight();
    window.addEventListener('resize', appHeight);
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  globalStyles();

  return (
    <ErrorBoundary>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

        <title>Cutters Hairdresser</title>
      </Head>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_DOMAIN}
        clientId={process.env.NEXT_PUBLIC_CLIENT_ID}
        audience={process.env.NEXT_PUBLIC_AUDIENCE}
        scope="read:users"
        redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage">
        <APIErrorProvider>
          <Provider store={store}>
            <CuttersProvider>
              <Component {...pageProps} />
            </CuttersProvider>
            <APIErrorNotification />
          </Provider>
        </APIErrorProvider>
        {!online && <ErrorMessage message={t('internetConnectionLost')} />}
      </Auth0Provider>
    </ErrorBoundary>
  );
};

export default appWithTranslation(MyApp);
