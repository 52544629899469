import * as React from 'react';

type APIError = {
  error: any;
  addError: (message: string, response?: any) => void;
  removeError: () => void;
};

export const APIErrorContext = React.createContext<APIError>({
  error: null,
  addError: () => {},
  removeError: () => {},
});

export const APIErrorProvider: React.FC = (props) => {
  const { children } = props;
  const [error, setError] = React.useState<any>(null);

  const addError = (message: string, response?: any) => {
    setTimeout(() => {
      setError(null);
    }, 5000);
    return setError({ message, response });
  };

  const removeError = () => setError(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pushToSentry = (error: any) => {};

  React.useEffect(() => {
    if (error) {
      pushToSentry(error);
    }
  }, [error]);

  const contextValue = {
    error,
    addError: React.useCallback((message, response) => addError(message, response), []),
    removeError: React.useCallback(() => removeError(), []),
  };

  return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
};
