import { PriceRule } from '@models';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const thunkActions = {
  fetchPricerules: createAsyncThunk(
    'pricerules/fetchPricerules',
    async (data: { couponCode: string, salonId?: string }): Promise<PriceRule[] | undefined> => {
      const response = await fetch(`/api/pricerules/${data.couponCode}${data.salonId ? `?salonId=${data.salonId}` : ''}`, { cache: 'no-store' });

      return Promise.resolve(await response.json());
    }),
};