import { useGet, useCurrentEmployeeWorkStatus, useErrorHandler } from 'hooks';
import { Salon } from 'models';

export const useCurrentSalon = () => {
  const { workStatus } = useCurrentEmployeeWorkStatus();
  const salonId = workStatus?.salonId ?? null;
  const { data, error } = useGet<Salon>(salonId ? `/salons/${salonId}` : null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useErrorHandler(data, error, 'failedToFetchSalon');

  return {
    salon: data,
    isLoading: !error && !data,
    error: error,
  };
};
