import { RootState, useAppDispatch } from '../../store';
import {
  resetCutNoteDetails,
  setCutNote,
  setCutNoteDetails,
  setResetCount,
  calculateFormState,
  rejectCutNote,
  allowCutNote,
  setCutNoteGender,
  setCutNoteName,
  setAllCutNotes,
  setSelectedCutNoteId,
} from '../../store/cutNote/cutNoteSlice';
import { Gender } from '@components/CutNote';
import { CutNote } from '@models';
import { useSelector } from 'react-redux';

export const useCutNoteStore = () => {
  const cutNote = useSelector((state: RootState) => state.cutNote.data.details);
  const gender = useSelector((state: RootState) => state.cutNote.data.gender);
  const resetCount = useSelector((state: RootState) => state.cutNote.resetCount);
  const dirty = useSelector((state: RootState) => state.cutNote.dirty);
  const rejected = useSelector((state: RootState) => state.cutNote.rejected);
  const name = useSelector((state: RootState) => state.cutNote.name);
  const allCutNotes = useSelector((state: RootState) => state.cutNote.allCutNotes);
  const selectedCutNoteId = useSelector((state: RootState) => state.cutNote.selectedCutNoteId);
  const dispatch = useAppDispatch();

  const removeNullValuesCutNote = (cutNote: CutNote) => {
    let cutNoteDetails: any = Object.keys(cutNote.details).reduce((obj: any, key) => {
      if (cutNote.details[key] !== null) {
        if (typeof cutNote.details[key] === 'boolean') {
          obj[key] = [cutNote.details[key]];
        } else if (
          Array.isArray(cutNote.details[key]) &&
          cutNote.details[key].every((item: any) => typeof item === 'number')
        ) {
          obj[key] = cutNote.details[key].map((item: any) => item.toString());
        } else {
          obj[key] = cutNote.details[key];
        }
      }
      return obj;
    }, {});
    if (Object.keys(cutNoteDetails).length === 0 && cutNoteDetails.constructor === Object) {
      cutNoteDetails = null;
    }
    return cutNoteDetails;
  };

  const addCutNoteToStore = (cutNote: CutNote) => {
    if (cutNote?.details) {
      const details = removeNullValuesCutNote(cutNote);

      dispatch(setCutNoteDetails({ details: details }));
      setCutNoteGenderAction(cutNote.gender);
      dispatch(calculateFormState());
    }
  };

  const addCutNoteDetailsToStore = (cutNoteDetails: any) => {
    dispatch(setCutNote(cutNoteDetails));
    dispatch(calculateFormState());
  };

  const resetCutNoteStore = () => {
    dispatch(resetCutNoteDetails());
    dispatch(setCutNoteGender({ gender: undefined }));
    dispatch(setResetCount());
    dispatch(calculateFormState());
  };

  const setCutNoteGenderAction = (gender: Gender) => {
    dispatch(setCutNoteGender({ gender }));
  };

  const setCutNoteNameAction = (name: string) => {
    dispatch(setCutNoteName({ name }));
  };

  const setAllCutNotesAction = (allCutNotes: CutNote[]) => {
    dispatch(setAllCutNotes({ allCutNotes }));
  };

  const setSelectedCutNoteIdAction = (selectedCutNoteId: string) => {
    dispatch(setSelectedCutNoteId({ selectedCutNoteId }));
  };

  const calculateFormStateAction = () => dispatch(calculateFormState());
  const rejectCutNoteAction = () => dispatch(rejectCutNote());
  const allowCutNoteAction = () => dispatch(allowCutNote());

  return {
    cutNote,
    gender,
    name,
    allCutNotes,
    selectedCutNoteId,
    addCutNoteToStore,
    resetCutNoteStore,
    addCutNoteDetailsToStore,
    setCutNoteName: setCutNoteNameAction,
    setAllCutNotes: setAllCutNotesAction,
    setCutNoteGender: setCutNoteGenderAction,
    setSelectedCutNoteId: setSelectedCutNoteIdAction,
    resetCount,
    dirty,
    rejected,
    calculateFormState: calculateFormStateAction,
    rejectCutNote: rejectCutNoteAction,
    allowCutNote: allowCutNoteAction,
  };
};
