import { API, AddTransactionPayload, UpdateTransactionPayload } from '@hooks';
import { CustomerQueueServerModel, Transaction } from '@models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { urlWithParams } from '@utils/helpers';
import { API_BASE_URL } from 'utils/global-constants';

export interface FetchQueueResponse {
  queue: CustomerQueueServerModel;
  employeeId: number;
}

export interface FetchQueuePayload {
  salonId: string | undefined;
  employeeId: number;
}

export const thunkActions = {
  fetchQueue: createAsyncThunk(
    'queue/fetchQueue',
    async (payload: FetchQueuePayload): Promise<FetchQueueResponse | undefined> => {
      if (payload.salonId === null) {
        return;
      }
      const response = await fetch(`${API_BASE_URL}/customerqueue/${payload.salonId}`);

      return Promise.resolve({
        queue: await response.json(),
        employeeId: payload.employeeId,
      });
    }),
  addCustomerToQueue: createAsyncThunk(
    'queue/addCustomerToQueue',
    async (data: { token: string; payload: AddTransactionPayload }) => {
      const { token, payload } = data;
      return apiAddTransaction(token, payload);
    },
  ),
  removeCustomerFromQueue: createAsyncThunk(
    'queue/removeCustomerFromQueue',
    async (data: { token: string; payload: UpdateTransactionPayload }) => {
      const { token, payload } = data;
      return apiUpdateTransaction(token, payload);
    },
  ),
};

// TODO: Figure out how to use together with hooks
const apiUpdateTransaction = async (token: string, payload: UpdateTransactionPayload) => {
  const { id, ...rest } = payload;

  const response = await fetch(urlWithParams(API_BASE_URL + API.UPDATE_TRANSACTION, { id }), {
    method: 'PUT',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rest),
  });

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(response.statusText);
};

const apiAddTransaction = async (token: string, payload: AddTransactionPayload): Promise<Transaction[]> => {
  const response = await fetch(API_BASE_URL + API.ADD_TRANSACTION, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  return response.json();
};
