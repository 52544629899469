import { CutNoteStateModel, DetailValueType } from '../cutNoteSlice';
import { PayloadAction } from '@reduxjs/toolkit';

interface SetCutNoteActionPayload {
  key: string;
  value: DetailValueType[];
}

export const setCutNote = (
  state: CutNoteStateModel,
  action: PayloadAction<SetCutNoteActionPayload>
) => {
  const { key, value } = action.payload;
  state.data.details[key] = value;
};
