import { RootState } from '../../store';
import { useSelector } from 'react-redux';

export const useEmployeeScheduledBreak = (transactionId: string | undefined, employeeId: number | undefined) => {
  const employeeScheduledBreak = useSelector((state: RootState) => {
    if(!transactionId || !employeeId) {
      return undefined;
    }

    return state.queue.scheduledBreaks.find(scheduledBreak => {
      return scheduledBreak.transactionId === transactionId && scheduledBreak.employeeId === `${employeeId}`;
    });
  });

  return { employeeScheduledBreak };
};
