import { BASE_APP_URL } from 'utils/global-constants';
import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';

export function useInventory(salonId: number | null, options = null) {
  const { getAccessTokenSilently } = useAuth0();
  const { data, error } = useSWR(
    salonId ? `${BASE_APP_URL}/api/inventory/${salonId}` : null,
    async (key) => {
      const accessToken = await getAccessTokenSilently({ scope: 'read:users' });
      const response = await fetch(key, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });
      return response.json();
    },
    options,
  );

  return {
    data,
    isLoading: !error && !data,
    error: error,
  };
}
