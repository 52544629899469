import { useSanity } from 'hooks';

export const useNotifications = (locale = 'en') => {
  const { data, error } = useSanity<Notification[]>(`
    *[_type == 'notifications' && 'hairdresser' in visible_in]{
      "title": title.${locale},
      "message": message.${locale},
      key
    }
  `);

  return {
    notifications: data,
    isLoading: !error && !data,
    error: error,
  };
};
